
import getCookie from '@/plugins/getCookie.js';
export default {
    name: 'fullpage',
    data: () => {
        return {
            cookieGo: false,
            isMobile: false
        }
    },
    created() {
        this.$store.commit('nav/CLOSE_MENU')
      // Adjust the breakpoint as needed
        if (process.client) {
          this.isMobile =   this.isMobile = window.innerWidth < 768 ? true : false; 
            const bodyClass = document.querySelector('body').classList
            bodyClass.remove('overflow-hidden')
            console.log('body', bodyClass)
        }
    },
    mounted() {
      if (!this.isMobile) {
            // Add scroll event listener only if not mobile
            window.addEventListener('scroll', this.handleScroll);
        }
  },

  beforeDestroy() {
    if (!this.isMobile) {
            // Clean up the scroll event listener only if not mobile
            window.removeEventListener('scroll', this.handleScroll);
        }
  },
    methods: {
        scrollToContent() {
            const selected = document.getElementById('scrollTo')
            selected.scrollIntoView({ behavior: "smooth", inline: "nearest" });
        },    cookieUpdated() {
            this.runAnimation()
            this.cookieGo = true
        },
        handleScroll() {
          if (this.isMobile) return; // Skip scroll handling if mobile
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      // Check if the user has scrolled to the bottom of the page
      if (scrollTop + windowHeight >= documentHeight - 10) { // Adjust the -10 for a slight buffer
        document.querySelector('.ch2-icon').classList.add('lifted');
      } else {
        document.querySelector('.ch2-icon').classList.remove('lifted');
      }
    }
    },
}
