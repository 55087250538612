
    import getCookie from '@/plugins/getCookie.js';
    
    export default {
        data() {
            return {
                sorry: false,
                visible: false
            }
        },
        mounted() {
            // Function to delete a cookie
            const deleteCookie = (name) => {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            };
    
            // Function to check if this is a new tab
            const isNewTab = () => {
                const tabOpened = sessionStorage.getItem('tabOpened');
                if (!tabOpened) {
                    sessionStorage.setItem('tabOpened', 'true');
                    return true;
                }
                return false;
            };
    
            // Delete the cookie only if this is a new tab
            if (isNewTab()) {
                deleteCookie("ageCheck");
            }
    
            // Check the cookie value to determine visibility
            this.visible = getCookie("ageCheck") == 'true' ? false : true;
        },
        methods: {
            age(val) {
                if (val) {
                    document.cookie = "ageCheck=true";
                    this.visible = false;
                    if (this.$route.name == 'index') {
                        this.$emit('cookieUpdated');
                    }
                } else {
                    this.sorry = true;
                }
            }
        }
    }
    