
export default {
    name: 'simple',
    created() {
        this.$store.commit('nav/CLOSE_MENU')
        this.$store.commit('page/FORCE_LIGHT')
        if (process.client) {
            const bodyClass = document.querySelector('body').classList
            bodyClass.remove('overflow-hidden')
            console.log('body', bodyClass)
        }
    },

}
