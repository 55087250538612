
export default {
    name: 'page',
    data: () => {
        return {
            featuredImage: '',
            darkMode: false,
            hideFeatured: false,
            isMobile: false
        }
    },

    created() {
        console.log(this.$route.params.slug)
     
        this.$store.commit('nav/CLOSE_MENU')
        this.$nuxt.$on('setPage', (data) => this.setPage(data))
        if (process.client) {
            this.isMobile = window.innerWidth < 768 ? true : false;
            const bodyClass = document.querySelector('body').classList
            bodyClass.remove('overflow-hidden')
        }
    },
    mounted() {
        if (!this.isMobile) {
            // Add scroll event listener only if not mobile
            window.addEventListener('scroll', this.handleScroll);
        }
  },

  beforeDestroy() {
    // Clean up the scroll event listener when the component is destroyed
    if (!this.isMobile) {
            // Clean up the scroll event listener only if not mobile
            window.removeEventListener('scroll', this.handleScroll);
        }
  },
    methods: {
        setPage(data) {
            this.featuredImage = data.featuredImage
            this.darkMode = data.darkMode
            this.hideFeatured = data.hideFeatured

        },
        scrollToContent() {
            const selected = document.getElementById('scrollTo')
            selected.scrollIntoView({
                behavior: "smooth",
                inline: "nearest"
            });
        },
        handleScroll() {
            if (this.isMobile) return; // Skip scroll handling if mobile
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      // Check if the user has scrolled to the bottom of the page
      if (scrollTop + windowHeight >= documentHeight - 10) { // Adjust the -10 for a slight buffer
        document.querySelector('.ch2-icon').classList.add('lifted');
      } else {
        document.querySelector('.ch2-icon').classList.remove('lifted');
      }
    }
    },
}
