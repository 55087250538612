import { render, staticRenderFns } from "./page.vue?vue&type=template&id=73474f15&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AgeCheck: require('/opt/build/repo/components/ageCheck.vue').default,TopNav: require('/opt/build/repo/components/topNav.vue').default})
